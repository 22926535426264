// Variables
$default-main-app-color: var(--var-color-primary);
$form-group-bottom-margin: 25px;
$form-group-right-margin: 7px;
$form-row-v-margin: 25px;
$form-label-color: #343434;
$input-border-color: #e1e1e1;
$input-border-width: 1px;
$input-border: $input-border-width solid $input-border-color;
$input-border-radius: 3px;
$input-font-size: 14px;
$input-line-height-value: 1.2;
$input-line-height: #{$input-line-height-value}em;
$input-padding: 7px;
$input-margin-top: 5px;
$input-min-height: 32px;
$btn-border-radius: 15px;
$form-info-color: #343434;
$form-group-content-padding-left: 20px;
$form-fieldset-group-margin-top: 15px;
$form-row-col-right-margin: 20px;
$fieldset-margin: 50px;
// Mixins
@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  box-sizing: $value;
}

@mixin box-shadow($xOffset: 0, $yOffset: 0, $blur: 10px, $spread: 0, $color: rgba(0, 0, 0, 0.15)) {
  -webkit-box-shadow: $xOffset $yOffset $blur $spread $color;
  box-shadow: $xOffset $yOffset $blur $spread $color;
}

@mixin box-shadow-unset {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

@mixin transition($value) {
  -webkit-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin display-inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-align-items-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex-align-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex-align-items-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

@mixin flex-align-items-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-justify-content-space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex-align-self-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-direction: column;
}

@mixin flex-grow($grow) {
  -webkit-box-flex: $grow;
  -webkit-flex-grow: $grow;
  -ms-flex-positive: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink: 0) {
  -webkit-flex-shrink: $shrink;
  -ms-flex-negative: $shrink;
  flex-shrink: $shrink;
}

@mixin first-child-no-margins($margin-side) {
  &:first-child {
    @each $side in $margin-side {
      margin-#{$side}: 0;
    }
  }
}

@mixin last-child-no-margins($margin-side) {
  &:last-child {
    @each $side in $margin-side {
      margin-#{$side}: 0;
    }
  }
}

@mixin first-of-type-no-margins($margin-side) {
  &:first-of-type {
    @each $side in $margin-side {
      margin-#{$side}: 0;
    }
  }
}

@mixin last-of-type-no-margins($margin-side) {
  &:last-of-type {
    @each $side in $margin-side {
      margin-#{$side}: 0;
    }
  }
}

@mixin flat-btn-hover-styles($bg-color: dimgray) {
  &:hover {
    background-color: $bg-color;
    color: white;
    border-radius: $btn-border-radius;
  }
}

@mixin btn-raised-common($border-radius: $btn-border-radius) {
  @extend %btn-common;
  border-radius: $border-radius;
  color: white;
  background-color: var(--var-color-primary);
}

@mixin btn-raised-common-hoverable($border-radius: $btn-border-radius) {
  &:hover {
    position: relative;
    span,
    img {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: $border-radius;
    }
  }
}

@mixin appearance($value: none) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin media-desktop() {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin form-element-font-size {
  /* This is the fix for safari which doesn't zoom out the input on blur event */
  @media (max-width: 799px) {
    font-size: 16px !important;
  }
}

// Placeholders
%btn-common {
  border: none;
  font-size: 14px;
  padding: 3px 7px;
  cursor: pointer;
}

%form-input {
  border: $input-border;
  border-radius: $input-border-radius;
  padding: $input-padding;
  width: 100%;
  @include form-element-font-size;
}
