@import './_main';
img,
svg {
  vertical-align: middle;
}

button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: block;
}

.app-designer-form,
.form-input {
  font-family: 'Montserrat', sans-serif;
}

.app-designer-form {
  @include box-sizing(border-box);
  overflow: hidden;
  max-width: 700px;
  &:not(:first-child) {
    margin-top: 25px;
  }
}

.app-designer-form {
  *,
  *::before,
  *::after {
    @include box-sizing(inherit);
  }
}

.form-row,
.form-tabs {
  margin: $form-row-v-margin 0;
}

.form-fieldset-groups,
.form-fieldset {
  margin: $fieldset-margin 0;
  @include first-child-no-margins(top);
  @include last-child-no-margins(bottom);
}

.form-fieldset {
  padding: 0;
  border: none;
  @include last-of-type-no-margins(bottom);
}

.form-fieldset-groups {
  @include first-child-no-margins(top);
  @include last-child-no-margins(bottom);
}

.form-fieldset-groups__content,
.form-tabs__content {
  margin-top: $input-margin-top;
  border-top: $input-border;
  padding: $form-fieldset-group-margin-top 0 $form-fieldset-group-margin-top $form-group-content-padding-left;
}

.form-fieldset-groups__content {
  &.form-fieldset-groups__content--no-pb {
    padding-bottom: 0;
  }
}

.form-tabs__content {
  border-bottom: $input-border;
}

.form-fieldset-group {
  border-bottom: $input-border;
  padding-bottom: 5px;
  &:not(:first-child) {
    margin-top: $form-fieldset-group-margin-top;
  }
  &.form-fieldset-group--compacted {
    border-bottom: 0;
    @include display-flex;
    @include flex-align-items-center;
  }
}

.form-fieldset-group-sticked:not(:first-child) {
  margin-top: $form-row-v-margin;
}

.form-row,
.form-row-groups,
.form-tabs,
.form-row-groups-wrapper {
  @include first-child-no-margins(top);
  @include last-child-no-margins(bottom);
}

.form-row-groups-wrapper {
  margin: $form-row-v-margin 0;
  .form-row-groups {
    margin: 0 0 (-$form-group-bottom-margin) 0;
  }
  &.form-row-groups-wrapper--m0 {
    margin: 0;
  }
}

.form-row-groups {
  @include display-flex;
  @include flex-align-items-start;
  @include flex-wrap;
  margin: $form-row-v-margin 0;
  &.form-row-groups--distanced {
    @include flex-align-items-end;
  }
}

.form-row-group {
  margin: 0 $form-group-right-margin $form-group-bottom-margin 0;
  @include last-child-no-margins(right);
  &.form-row-group--hidden {
    display: none;
  }
  &.form-row-group--distanced {
    margin-right: 45px;
    @include last-child-no-margins(right);
  }
  &.form-row-group--before-subgroups {
    margin-right: 20px;
  }
  &.form-row-group--with-subgroups {
    @include display-inline-flex;
    @include flex-wrap;
    margin-bottom: 0;
  }
  &.form-row-group--column {
    width: 48%;
  }
  &.form-row-group--no-mb {
    margin-bottom: 0;
  }
  &.form-row-group--mt-as-form-input {
    margin-top: $input-margin-top;
  }
}

.form-row-groups__caption {
  width: 100%;
  margin-bottom: 5px;
  color: $form-label-color;
}

.form-label,
.form-row-groups__caption,
.form-fieldset-groups__caption,
.form-tabs__labels {
  font-weight: 600;
  color: $form-label-color;
}

.form-label {
  &.form-label--checkbox,
  &.form-label--radio {
    display: inline-block;
    line-height: normal;
  }
  &.form-label--no-font-weight {
    font-weight: 400;
  }
  &.form-label--inline {
    display: inline-block;
  }
}

.form-input {
  @extend %form-input;
  margin-top: $input-margin-top;
}

.form-input {
  &.form-input--select {
    @include appearance;
    height: $input-min-height;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNSIgd2lkdGg9IjEwIj48cGF0aCBkPSJNMCAwbDUgNSA1LTV6IiBmaWxsPSIjOTk5Ii8+PC9zdmc+);
    padding-right: 34px !important;
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-position: calc(100% - 12px) center;
  }

  &.form-input--number {
    $width: 50px;
    display: inline-block;
    max-width: $width;
    min-width: $width;
  }

  &.form-input--height {
    $width: 70px;
    max-width: $width;
    min-width: $width;
    display: inline-block;
  }

  &.form-input--order-number {
    $width: 75px;
    max-width: $width;
    min-width: $width;
  }

  &.form-input--email,
  &.form-input--extension {
    max-width: 200px;
  }

  &.form-input--twitter-htag,
  &.form-input--twitter-id {
    max-width: 250px;
  }

  &.form-input--title,
  &.form-input--text,
  &.form-input--alt,
  &.form-input--textarea {
    max-width: 300px;
  }

  &.form-input-max-50-chars {
    max-width: 330px;
  }

  &.form-input--question,
  &.form-input--long-text,
  &.form-input--long-textarea,
  &.form-input--url {
    max-width: 500px;
  }

  &.form-input--before-inline-btn {
    margin-right: $form-group-right-margin;
    margin-top: 0;
  }

  &.form-input--sticked-left,
  &.form-input--sticked-right {
    margin-top: 0;
    @include box-shadow-unset;
  }

  &.form-input--sticked-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.form-input--sticked-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }

  &.form-input--mt-0 {
    margin-top: 0;
  }
}

.final-url {
  max-width: 100%;
}

.form-label,
.form-input,
.form-row-groups__caption,
.form-tabs__labels,
.form-fieldset-groups__caption {
  font-size: $input-font-size;
  line-height: $input-line-height;
}

.form-label,
.form-input,
.form-row-groups__caption,
.form-fieldset-groups__caption {
  display: block;
}

.form-input-checkbox,
.form-input-radio {
  vertical-align: middle;
  margin-left: 1px;
}

.form-input-radio {
  margin-top: 0;
  margin-bottom: 0;
  &.form-input-radio--group {
    margin-left: 15px;
  }
}

.form-input-number-units {
  display: inline-block;
  margin-left: 5px;
  color: $form-info-color;
}

.form-colorpicker {
  $width: $input-min-height;
  height: $width;
  width: $width;
  border: $input-border;
  border-radius: $input-border-radius;
  margin-top: $input-margin-top;
  display: block;
  @include box-shadow;
  cursor: pointer;
}

.final-url {
  font-size: 13px;
  margin-top: 5px;
  color: $form-label-color;
}

.final-url__label {
  font-weight: 500;
}

.final-url__value,
.form-field-info {
  color: $form-info-color;
}

.form-error-msg {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.form-input.ng-invalid.ng-touched,
.form-input.ng-invalid.show-error {
  border-color: red;
}

.form-field-info {
  $font-size: 13px;
  margin-top: 5px;
  font-size: $font-size;
  display: block;
  font-style: italic;
  line-height: $font-size;
}

.form-action-btns {
  margin-top: 20px;
}

.btns-spaced-between-wrapper {
  @include display-flex;
  @include flex-align-items-center;
  @include flex-justify-content-space-between;
  margin-bottom: 5px;
  &:not(:first-child) {
    margin-top: $form-fieldset-group-margin-top;
  }
}

.form-action-btn {
  @include btn-raised-common;
  margin-right: 10px;
  @include last-child-no-margins(right);
  /* Declare hover state for desktop screens */
  @include media-desktop() {
    &:hover {
      @include btn-raised-common-hoverable;
    }
  }
  &.form-action-btn--delete {
    background-color: red;
  }
}

.btn-flat {
  @extend %btn-common;
  background: transparent;
  color: dimgray;
  @include flat-btn-hover-styles;
  &.btn-flat--remove,
  &.btn-flat--remove-compact {
    color: red;
    @include flat-btn-hover-styles(red);
  }
  &.btn-flat--remove {
    float: right;
  }
  &.btn-flat--add-new-item:not(:first-child) {
    margin-top: $form-fieldset-group-margin-top;
  }
}

.hyphen {
  margin: -3px 3px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.btn-inline-delete-wrapper {
  @include display-flex;
  @include flex-align-items-center;
  margin-top: $input-margin-top;
}

.btn-inline-delete {
  @include btn-raised-common(50%);
  font-size: 13px;
  /* Declare hover state for desktop screens */
  @include media-desktop() {
    &:hover {
      @include btn-raised-common-hoverable(50%);
    }
  }
}

.form-label-required {
  font-size: 10px;
  font-weight: 400;
}

.form-tab-label {
  color: $form-label-color;
  margin-right: 15px;
  font-size: 16px;
  cursor: pointer;
  @include last-child-no-margins(right);
  &.form-tab-label--active {
    text-decoration: underline;
  }
}

.form-input-sticked-wrapper {
  margin-right: $form-group-right-margin;
  @include display-flex;
  @include flex-align-items-center;
  @include box-shadow;
}

.form-sticked-container {
  margin-top: $input-margin-top;
  @include display-flex;
  @include flex-align-items-center;
}

.form-row-cols-wrapper {
  margin: $form-group-bottom-margin 0;
  @include first-child-no-margins(top);
  @include last-child-no-margins(bottom);
  &.form-row-cols-wrapper--mt0 {
    margin-top: 0;
  }
}

.form-row-cols {
  @include display-flex;
  @include flex-wrap;
  margin: 0 (-$form-row-col-right-margin) (-$form-group-bottom-margin) 0;
}

.form-row-cols__col {
  margin-bottom: $form-group-bottom-margin;
  &.form-row-cols__col--max-50-chars {
    max-width: 330px;
  }
}

.form-row-cols__col__content {
  margin-right: $form-row-col-right-margin;
}

.flex-grow-1 {
  @include flex-grow(1);
}

.flex-grow-2 {
  @include flex-grow(2);
}

.vertical-align-center-content {
  @include display-flex;
  @include flex-align-items-center;
}

.group-aligned-with-input {
  min-height: $input-min-height;
}

.mt-as-form-input {
  margin-top: $input-margin-top;
}

.flex-align-self-start {
  @include flex-align-self-start;
}

.form-radio-btns-group {
  display: inline-block;
  margin-left: 15px;
  vertical-align: text-top;
}
