@import '../../assets/style/app-designer-form-theme/_main';
// Variables
$first-bg-color: #f5f5f5;
$second-bg-color: #fff;
$third-bg-color: #efeeee;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
$first-border-color: #e1e1e1;
$main-border-radius: 10px;
$btn-border-radius: 15px;
$disable-state-color: #e8e8e8;
$disable-state-opacity: 0.3;
$widget-border-color: gray;
$max-widgets-width: 350px;
$default-content-padding: 10px;
$form-input-h-padding: 7px;
$icon-btn-dim: 34px;
$sidebar-menu-text-left-margin: 10px;
$sidebar-menu-item-padding: 7px;
$hover-btn-color: rgba(0, 0, 0, 0.4);
$main-gray-color: #757575;
$list-item-content-horizontal-padding: 14px;
$list-item-content-paddings: 7px $list-item-content-horizontal-padding;
$margin-between-group-and-list: 5px;
$application-media-query-point-1: 694px;
$app-header-icon-btn-margin: 7px;
$warning-msg: #9f6000;
// Mixins
@mixin text-overflow($value) {
  -o-text-overflow: $value;
  text-overflow: $value;
}

@mixin translateX($value) {
  -webkit-transform: translateX($value);
  -ms-transform: translateX($value);
  transform: translateX($value);
}

@mixin translateY($value) {
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  transform: translateY($value);
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin rotate($x) {
  -webkit-transform: rotate($x);
  -ms-transform: rotate($x);
  transform: rotate($x);
}

@mixin flex($grow, $shrink, $basis) {
  -webkit-box-flex: $grow;
  -ms-flex: $grow $shrink $basis;
  -webkit-flex: $grow $shrink $basis;
  flex: $grow $shrink $basis;
}

@mixin display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-align-self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

@mixin flex-align-self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@mixin flex-align-self-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@mixin flex-justify-content-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@mixin flex-justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin flex-justify-content-flex-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-no-wrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@mixin position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

@mixin hovered-btn($border-radius: 0) {
  &:hover {
    position: relative;
    svg,
    span,
    img {
      position: relative;
      z-index: 2;
    }
    &::after {
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      @if ($border-radius) {
        border-radius: $border-radius;
      }
      position: absolute;
      background-color: $hover-btn-color;
      z-index: 1;
    }
  }
}

@mixin column-count($columns) {
  -webkit-column-count: $columns;
  -moz-column-count: $columns;
  column-count: $columns;
}

@mixin column-gap($gap) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin column-width($width) {
  -webkit-column-width: 320px;
  -moz-column-width: 320px;
  column-width: 320px;
}

// Placeholders
%widget-results-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    margin-bottom: 25px;
    @include last-child-no-margins(bottom);
  }
}

%default-font-family {
  font-family: 'Montserrat', sans-serif;
}

%multiple-cols-row {
  @include display-flex;
  @include flex-wrap;
  @include flex-justify-content-space-between;
}

%form-elements-commons {
  border: 1px solid $first-border-color;
  border-radius: 5px;
  @include box-shadow;
}

%btn-common {
  display: inline-block;
  color: #fff;
  background-color: $default-main-app-color;
  font-size: 14px;
  text-decoration: none;
  padding: 3px 7px;
  text-align: center;
  border: none;
}

%content-centered {
  width: 100%;
  @media (min-width: 1400px) {
    width: 70%;
  }
  margin: 0 auto;
  overflow: visible;
  height: auto;
  min-height: 100%;
  @include box-shadow;
  background-color: $second-bg-color;
}

%form-legend {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  color: var(--var-form-label-color);
}
