/* stylelint-disable */
.main-wrapper {
  @include display-flex;
  @include flex-direction-column;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.main-wrapper__header {
  @include flex-shrink(0);
  position: relative;
  z-index: 1;
  @include box-shadow;
}

.main-wrapper__content {
  @include flex-grow(1);
  @include display-flex;
  /* Fallback for FF, Edge, IE browsers.
     * These don't set the height of the element
     * with the remaining height of the viewport height.
     * 64px is the height of the div#main-header.
     */
  height: calc(100% - 64px);
  &.main-wrapper__content--application {
    // 104px || 138px is the height of .main-wrapper__header
    height: calc(100% - 138px);
    @media (min-width: $application-media-query-point-1) {
      height: calc(100% - 104px);
    }
    background-color: $first-bg-color;
    overflow: auto;
  }
}

.main-wrapper__fake-footer {
  @include flex-shrink(0);
}

.sidebar-wrapper,
.content-wrapper,
.panels-wrapper,
.first-panel,
.second-panel,
.content {
  height: 100%;
}

.sidebar-wrapper {
  @include flex-shrink(0);
  background-color: var(--var-color-accent-dark);
  overflow: auto;
}

.content-wrapper {
  @include flex-grow(1);
}

.panels-wrapper {
  @include display-flex;
  overflow: hidden;
}

.first-panel,
.second-panel {
  width: 50%;
}

.first-panel,
.second-panel,
.content {
  overflow: auto;
  padding: $default-content-padding;
}

.first-panel {
  background-color: $third-bg-color;
}

.second-panel {
  background-color: $first-bg-color;
  &.second-panel--preview-widgets {
    background-color: #fff;
  }
}

.content {
  width: 100%;
  &.content--centered {
    @extend %content-centered;
  }
}

.app-button-color {
  background-color: var(--var-color-primary);
}
