.action-message-time-tooltip.mat-mdc-tooltip {
  margin-left: -2px;
  font-size: 14px;
}

.mat-mdc-snack-bar-container.error-snack {
  background-color: #da3341;
}

.mat-mdc-snack-bar-container.success-snack {
  background-color: #4bac3b;
}
