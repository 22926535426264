/* stylelint-disable value-keyword-case */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.all-component-typographies;
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$white-text: #efefef;
$dark-text: #343434;
$primary-palette: (
  50: #d8e7eb,
  100: #d8e7eb,
  200: #89bec9,
  300: #89bec9,
  400: #3296b1,
  500: #3296b1,
  600: #2c869f,
  700: #2c869f,
  800: #19748d,
  900: #19748d,
  A100: #d8e7eb,
  A200: #89bec9,
  A400: #3296b1,
  A700: #19748d,
  contrast: (
    50: $dark-text,
    100: $dark-text,
    200: $dark-text,
    300: $dark-text,
    400: $white-text,
    500: $white-text,
    600: $white-text,
    700: $white-text,
    800: $white-text,
    900: $white-text,
    A100: $dark-text,
    A200: $dark-text,
    A400: $white-text,
    A700: $white-text
  )
);
$accent-palette: (
  50: #f6f8fa,
  100: #d5d7d8,
  200: #9faab4,
  300: #9faab4,
  400: #9faab4,
  500: #4d4f5c,
  600: #4d4f5c,
  700: #4d4f5c,
  800: #343434,
  900: #343434,
  A100: #d5d7d8,
  A200: #9faab4,
  A400: #4d4f5c,
  A700: #343434,
  contrast: (
    50: $dark-text,
    100: $dark-text,
    200: $dark-text,
    300: $dark-text,
    400: $dark-text,
    500: $white-text,
    600: $white-text,
    700: $white-text,
    800: $white-text,
    900: $white-text,
    A100: $dark-text,
    A200: $dark-text,
    A400: $white-text,
    A700: $white-text
  )
);
$warn-palette: (
  50: #ffd9d9,
  100: #ffd9d9,
  200: #ff8c8c,
  300: #ff8c8c,
  400: #ff4141,
  500: #ff4141,
  600: #c33,
  700: #c33,
  800: #992626,
  900: #992626,
  A100: #ffd9d9,
  A200: #ff8c8c,
  A400: #ff4141,
  A700: #992626,
  contrast: (
    50: $dark-text,
    100: $dark-text,
    200: $dark-text,
    300: $dark-text,
    400: $white-text,
    500: $white-text,
    600: $white-text,
    700: $white-text,
    800: $white-text,
    900: $white-text,
    A100: $dark-text,
    A200: $dark-text,
    A400: $white-text,
    A700: $white-text
  )
);
$custom-theme-primary: mat.define-palette($primary-palette);
$custom-theme-accent: mat.define-palette($accent-palette);
$custom-theme-warn: mat.define-palette($warn-palette);

// The warn palette is optional (defaults to red).
$my-typography: mat.define-typography-config(
  $font-family: '"Montserrat", sans-serif',
  $headline-5:
    mat.define-typography-level(
      $font-size: 24px,
      $line-height: 32px,
      $letter-spacing: 0.15px,
      $font-weight: 500
    ),
  $headline-2:
    mat.define-typography-level(
      $font-size: 18px,
      $line-height: 24px,
      $letter-spacing: 0.15px,
      $font-weight: 500
    ),
  $body-2:
    mat.define-typography-level(
      $font-size: 14px,
      $line-height: normal,
      $letter-spacing: 0.5px,
      $font-weight: 500
    ),
  $subtitle-2:
    mat.define-typography-level(
      $font-size: 14px,
      $line-height: 20px,
      $letter-spacing: 0.25px,
      $font-weight: 600
    ),
  $caption:
    mat.define-typography-level(
      $font-size: 12px,
      $line-height: 16px,
      $letter-spacing: 0.4px,
      $font-weight: 400
    ),
  $button:
    mat.define-typography-level(
      $font-size: 14px,
      $line-height: normal,
      $letter-spacing: 1.25px,
      $font-weight: 500
    ),
  $body-1:
    mat.define-typography-level(
      $font-size: 14px,
      $line-height: 24px,
      $letter-spacing: 0.15px,
      $font-weight: 500
    ),
  $subtitle-1:
    mat.define-typography-level(
      $font-size: 18px,
      $line-height: 20px,
      $letter-spacing: 0.1px,
      $font-weight: 500
    )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn
    ),
    typography: $my-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($theme);

// STYLES AFTER MIGRATION ON MDC COMPONENTS

/* RADIO BUTTON */
@include mat.radio-density(-1);

.mdc-radio {
  // stylelint-disable-next-line declaration-no-important
  padding: 3px !important;
}

mat-radio-button {
  // stylelint-disable-next-line declaration-no-important
  display: block !important;
  margin: 4px 0;
}

/* CHECKBOX */
@include mat.checkbox-density(-1);

.mat-mdc-checkbox {
  // stylelint-disable-next-line declaration-no-important
  display: block !important;
}

/* CARD */
mat-card {
  padding: 12px;
}

/* SNACKBAR */
.mat-mdc-snack-bar-label,
.mdc-snackbar__label,
.mdc-snackbar__surface {
  // stylelint-disable-next-line declaration-no-important
  padding: 0 !important;
  // stylelint-disable-next-line declaration-no-important
  color: #000 !important;
  // stylelint-disable-next-line declaration-no-important
  background-color: #fff !important;
}

// STYLES AFTER MIGRATION ON MDC COMPONENTS

// Global variables for colors and typography
:root {
  --var-color-primary: #{mat.get-color-from-palette($primary-palette, 500)};
  --var-color-primary-light: #{mat.get-color-from-palette($primary-palette, 300)};
  --var-color-primary-dark: #{mat.get-color-from-palette($primary-palette, 800)};
  --var-color-warn: #{mat.get-color-from-palette($warn-palette, 500)};
  --var-color-warn-light: #{mat.get-color-from-palette($warn-palette, 300)};
  --var-color-warn-dark: #{mat.get-color-from-palette($warn-palette, 800)};
  --var-color-accent: #{mat.get-color-from-palette($accent-palette, 500)};
  --var-color-accent-light: #{mat.get-color-from-palette($accent-palette, 300)};
  --var-color-accent-dark: #{mat.get-color-from-palette($accent-palette, 800)};
}

@mixin custom-body {
  font-family: mat.font-family($my-typography, 'body-2');
  font-size: mat.font-size($my-typography, 'body-2');
  font-weight: mat.font-weight($my-typography, 'body-2');
  line-height: mat.line-height($my-typography, 'body-2');
  letter-spacing: mat.letter-spacing($my-typography, 'body-2');
}

@mixin custom-button {
  font-family: mat.font-family($my-typography, 'button');
  font-size: mat.font-size($my-typography, 'button');
  font-weight: mat.font-weight($my-typography, 'button');
  line-height: mat.line-height($my-typography, 'button');
  letter-spacing: mat.letter-spacing($my-typography, 'button');
}

@mixin custom-caption {
  font-family: mat.font-family($my-typography, 'caption');
  font-size: mat.font-size($my-typography, 'caption');
  font-weight: mat.font-weight($my-typography, 'caption');
  line-height: mat.line-height($my-typography, 'caption');
  letter-spacing: mat.letter-spacing($my-typography, 'caption');
}

@mixin custom-headline {
  font-family: mat.font-family($my-typography, 'headline-5');
  font-size: mat.font-size($my-typography, 'headline-5');
  font-weight: mat.font-weight($my-typography, 'headline-5');
  line-height: mat.line-height($my-typography, 'headline-5');
  letter-spacing: mat.letter-spacing($my-typography, 'headline-5');
}

@mixin custom-subheading-1 {
  font-family: mat.font-family($my-typography, 'body-1');
  font-size: mat.font-size($my-typography, 'body-1');
  font-weight: mat.font-weight($my-typography, 'body-1');
  line-height: mat.line-height($my-typography, 'body-1');
  letter-spacing: mat.letter-spacing($my-typography, 'body-1');
}

@mixin custom-subheading-2 {
  font-family: mat.font-family($my-typography, 'subtitle-1');
  font-size: mat.font-size($my-typography, 'subtitle-1');
  font-weight: mat.font-weight($my-typography, 'subtitle-1');
  line-height: mat.line-height($my-typography, 'subtitle-1');
  letter-spacing: mat.letter-spacing($my-typography, 'subtitle-1');
}

@mixin custom-body-2 {
  font-family: mat.font-family($my-typography, 'subtitle-2');
  font-size: mat.font-size($my-typography, 'subtitle-2');
  font-weight: mat.font-weight($my-typography, 'subtitle-2');
  line-height: mat.line-height($my-typography, 'subtitle-2');
  letter-spacing: mat.letter-spacing($my-typography, 'subtitle-2');
}

@mixin custom-headline-2 {
  font-family: mat.font-family($my-typography, 'headline-2');
  font-size: mat.font-size($my-typography, 'headline-2');
  font-weight: mat.font-weight($my-typography, 'headline-2');
  line-height: mat.line-height($my-typography, 'headline-2');
  letter-spacing: mat.letter-spacing($my-typography, 'headline-2');
}

body {
  @include custom-body;
}

button {
  @include custom-button;
}

caption {
  @include custom-caption;
}

h1 {
  @include custom-headline;
}

h2 {
  @include custom-headline-2;
}

h3 {
  @include custom-subheading-2;
}

h4 {
  @include custom-body-2;
}

h5 {
  @include custom-body-2;
}

h6 {
  @include custom-body-2;
}
