/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');
// stylelint-disable
@import 'app/shared/_common';
@import '_layout';
@import './assets/style/app-designer-form-theme/form-theme';
@import './assets/style/material';

$primary-color: #3296b1;
$warning-color: #f39c13;
$error-color: #ff4141;
$accent-color: #9faab4;

html {
  @include box-sizing(border-box);
  @extend %default-font-family;
}

html,
body {
  height: 100%;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--var-color-accent-light);
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--var-color-primary-light);
}

*,
*::before,
*::after {
  @include box-sizing(inherit);
}

body {
  margin: 0;
  @include first-child-no-margins(top);
  @include last-child-no-margins(bottom);
  background-color: #fff;
}

$svg-element-color: #fff;
.svg-element--white-fill {
  fill: $svg-element-color;
}

.svg-element--white-stroke {
  stroke: $svg-element-color;
}

.icon-btn,
.btn,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.hidden {
  display: none;
}

.icon-btn {
  $icon-btn-border-radius: 50%;
  padding: 0;
  background-color: $default-main-app-color;
  border: none;
  width: $icon-btn-dim;
  height: $icon-btn-dim;
  border-radius: $icon-btn-border-radius;
  @include display-inline-flex;
  @include flex-align-items-center;
  @include flex-justify-content-center;
  /* Declare hover state for desktop screens */
  @include media-desktop() {
    @include hovered-btn($icon-btn-border-radius);
  }
  /* Fix for Safari 9.3.5 bug: display:inline-flex not fully supported */
  svg,
  span {
    margin: 0 auto;
  }
}

.btn {
  @extend %btn-common;
  border-radius: $btn-border-radius;
  /* Declare hover state for desktop screens */
  @include media-desktop() {
    @include hovered-btn($btn-border-radius);
  }
}

.list {
  margin: 5px 0;
  padding: 0;
  > li {
    @include display-flex;
    margin: 10px 0;
    @include first-child-no-margins(top);
    @include last-child-no-margins(bottom);
  }
}

.list-item {
  @include flex-grow(1);
  color: #000;
  background-color: $second-bg-color;
  border: 1px solid $first-border-color;
  border-radius: $main-border-radius;
  @include box-shadow;
  @include display-flex;
  @include flex-align-items-center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  &.disabled {
    background-color: $disable-state-color;
    .item-content {
      opacity: $disable-state-opacity;
    }
  }
  .item-content {
    @include flex-grow(1);
    padding: $list-item-content-paddings;
  }
  .item-links {
    @include display-flex;
    @include flex-wrap;
    @include flex-justify-content-flex-end;
    padding: 5px;
    .btn {
      margin: 5px;
      visibility: hidden;
    }
  }
  .icon-btn {
    visibility: hidden;
  }
  &:hover,
  &.active {
    background-color: #5a5a5a;
    color: #fff;
    cursor: pointer;
    .icon-btn {
      visibility: visible;
    }
    .item-links .btn {
      visibility: visible;
    }
  }
}

.group-title {
  margin: 20px 0 $margin-between-group-and-list;
  font-size: 16px;
  padding-inline-start: $list-item-content-horizontal-padding;
  font-weight: 500;
}

.group-title + .list {
  margin-top: $margin-between-group-and-list;
}

.form-owl-date-time .owl-datetime-main-input {
  @extend .form-input;
  @include box-sizing(content-box);
  height: $input-line-height;
  width: auto;
}

$header-h-padding: 44px;
.app-list-header {
  min-height: 100px;
  background: transparent url('./assets/images/app-list/app_list_cover.jpg') bottom/cover no-repeat;
  padding: 15px $header-h-padding;
  position: relative;
}

.icon-btn.icon-btn--back {
  position: absolute;
  top: 0;
  left: 0;
  margin: $app-header-icon-btn-margin;
}

.app-list-header__logo {
  $w: 300px;
  width: 90%;
  max-height: 55px;
  margin: 10px 0;
  padding: 0 20px;
  @media (min-width: $header-h-padding * 2 + $w) {
    width: $w;
  }
}

.icon-btn__svg-logout-el {
  fill: none;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.cke_screen_reader_only.cke_copyformatting_notification {
  height: 0;
}

.corp-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.over-drag-icon {
  cursor: pointer;
  position: absolute;
  width: 15px;
  height: 35px;
  opacity: 0;
}

.toggle-children-placeholder {
  display: none;
}

.warning-label {
  color: $warning-msg;
  font-size: 14px;
  margin-left: 5px;
}

.error-label {
  color: red;
}

.warning-icon--dimensions {
  width: 17px;
  height: 17px;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.position-relative {
  position: relative;
}

.input-spinner {
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

.form-action-btn:first-child {
  margin-left: 0;
}

.form-action-btn:hover:hover::after {
  content: none;
}

.popin-dialog-container .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 10px;
}

// class="no-padding" is used to remove padding from mat-form-field
// <mat-form-field class="no-padding">
.mat-mdc-form-field.no-padding > .mat-form-field-wrapper {
  padding: 0;
}

// class="no-margin" is used to remove margin from mat-form-field
// <mat-form-field class="no-margin">
.mat-mdc-form-field.no-margin > .mat-form-field-wrapper {
  margin: 0;
}

// class="padding-block-end" add 10px padding for mat-form-field component
.mat-mdc-form-field.padding-block-end {
  padding-block-end: 10px;
}

// class="bc-transparent" is used to make background color of input
// from mat-form-field with appearance fill to transparent
// <mat-form-field class="bc-transparent" appearance=fill>
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.mat-mdc-snack-bar-container {
  background: #fff!important;
  color: black !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin-top: 50px !important;
}

.cdk-drag {
  &-preview {
    display: flex;
  }

  &-placeholder {
    opacity: 0;
  }

  &-animating {
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
  }
}

.max-height-600px {
  max-height: 600px !important;
}
